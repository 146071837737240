<template>
  <component
    :is="isGridDisabled ? 'div' : 'grid-layout'"
    class="grid-layout"
    :class="{
      'grid-layout--landing': isLandingSkin,
      'grid-layout--disabled': isGridDisabled,
      'grid-layout--editing': isEditable,
    }"
    :layout.sync="layout"
    :col-num="colNum"
    :row-height="30"
    :margin="[10, 10]"
    :is-draggable="isEditable"
    :is-resizable="isEditable"
    :is-mirrored="false"
    vertical-compact
    :responsive="false"
    auto-size
    use-css-transforms
    @layout-updated="layoutUpdatedEvent"
  >
    <component
      :is="isGridDisabled ? 'div' : 'grid-item'"
      v-for="item in isGridDisabled ? mobileLayout : layout"
      :key="item.i"
      :x="item.x"
      :y="item.y"
      :w="item.w"
      :h="item.h"
      :i="item.i"
      class="grid-item"
      :class="[
        `grid-item--${item.i}`,
        getLayoutItemContainerProps(item).class || '',
      ]"
      v-bind="getLayoutItemContainerProps(item)"
    >
      <component
        :is="components[item.appKey]"
        class="w-100 h-100"
        :app="item.app"
        v-bind="item.props || {}"
      />
    </component> 
  </component>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
// import { GridLayout, GridItem } from 'vue-grid-layout/src/components';
// import { BCard } from 'bootstrap-vue';
import {
  ENABLED_APPS_ACTIONS,
  ENABLED_APPS_GETTERS,
  ENABLED_APPS_MUTATIONS,
} from '@/store/enabled-apps/enabled-apps-store-constants';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';
// import { randomString } from '@/@core/utils/utils';

export default {
  name: 'AppsLayout',
  props: {
    elems: {
      type: [Array, Object],
      required: false,
    },
  },
  components: {
    // GridLayout: () => import('vue-grid-layout/src/components/GridLayout' /* webpackChunkName: "GridLayout" */),
    // GridItem: () => import('vue-grid-layout/src/components/GridItem' /* webpackChunkName: "GridItem" */),
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    // BCard,
  },
  mixins: [GridStatusMixin],
  inject: ['$chatBot'],
  data() {
    return {
      components: {},
      isLoadingComponents: true,
      // NOTE: I need to use local layout because VueGridLayout is modifying the layout,
      // even if I don't use `.sync`.
      layout: [],
      unsubscribeAction: null,
    };
  },
  computed: {
    savedLayout() {
      return this.isLoadingComponents
        ? []
        : this.$store.getters[ENABLED_APPS_GETTERS.savedLayout];
    },
    mobileLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.mobileLayout];
    },
    enabledApps() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps];
    },
    elements() {
      return this.$store.getters.elements.unpaginated;
    },
    addon() {
      return this.$store.getters.addon;
    },
    componentsLoaders() {
      return this.$store.getters[ENABLED_APPS_GETTERS.componentsLoaders];
    },
    isEditable() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
    colNum() {
      return 12;
    },
    defaultWidth() {
      return 6;
    },
    defaultHeight() {
      return 10;
    },
  },
  watch: {
    savedLayout(newLayout) {
      this.layout = JSON.parse(JSON.stringify(newLayout));
    },
    isEditable(isEditable) {
      if (!isEditable) {
        this.layout = JSON.parse(JSON.stringify(this.savedLayout));
        this.$chatBot.show();
      } else {
        this.$chatBot.hide();
      }
    },
    addon(newVal) {
      if (newVal.unpaginated.length === 0 || this.addon.unpaginated.length === 0) {
        return;
      }
      if (newVal.unpaginated.length > 0) {
        const indexAppToRemove = this.layout.findIndex((app) => app.appKey === newVal.unpaginated[0].addonKey);
        const newLayout = [...this.layout];
        newLayout.splice(indexAppToRemove, 1);
        this.layout = JSON.parse(JSON.stringify(newLayout));

        this.updateLayout();

        this.$store.commit('SET_ITEM_LITERAL', {
          itemType: 'addon',
          data: {
            paginated: {},
            unpaginated: [],
          },
        });
      }
    },
    async elements(newVal, oldVal) {
      // console.log('WATCH ELEMENTS --->>>>> ', newVal)
      if (Array.isArray(newVal)) {
        newVal = newVal[0];
      }
      // console.log('AppsLayout this.elements', this.elements);
      // console.log('this.elems from props', this.elems);
      //TODO: check what happens if we change it to this.elems, that come from Home through props
      //if (this.elems.length === 0) {
      if (this.elements.length === 0) {
        return;
      }
      if (newVal.created) {
        const newElements = [];

        //for (const row of this.elems) {
        for (const row of this.elements) {
          let item = row;
          let index = row.key;
          item = {
            itemKey: row.key,
            ...item,
            y: -0.1,
            x: 0,
            h: 4,
            w: 4,
            i: index,
            appKey: index,
            props: { element: item },
          };
          newElements.push(item);

          const toLoad = {
            loadComponent: () => import('@/views/apps/elements/widgets/ElementsListWidget.vue'),
            props: { item },
          };
          this.components[index] = (await toLoad.loadComponent()).default;
        }

        const newLayout = [...this.layout, ...newElements];

        this.layout = JSON.parse(JSON.stringify(newLayout));
      }

      if (newVal.edited) {
        console.log('This Element was edited!');
        // EDIT
        const itemIndex = this.layout.findIndex((x) => x.appKey === newVal.key);

        const item = this.layout[itemIndex];

        const itemKeys = Object.keys(item);

        for (const row of Object.keys(newVal)) {
          if (itemKeys.includes(row)) {
            item[row] = newVal[row];
          }
        }

        const newLayout = [...this.layout];

        newLayout[itemIndex] = item;

        const toLoad = {
          loadComponent: () => import('@/views/apps/elements/widgets/ElementsListWidget.vue'),
          props: { item },
        };
        this.components[item.appKey] = (await toLoad.loadComponent()).default;

        this.layout = JSON.parse(JSON.stringify(newLayout));
      }

      if (newVal.deleted) {
        // DELETE
        const itemIndex = this.layout.findIndex((x) => x.appKey === newVal.key);

        const newLayout = [...this.layout];

        newLayout.splice(itemIndex, 1);

        this.layout = JSON.parse(JSON.stringify(newLayout));
      }

      console.log('Commiting elements...');
      this.$store.commit('SET_ITEM_LITERAL', {
        itemType: 'elements',
        data: {
          paginated: {},
          unpaginated: [],
        },
      });
      this.$store.commit('SET_ITEM_LITERAL', {
        itemType: 'elementsKeysList',
        data: {
          paginated: {},
          unpaginated: [
            ...(this.$store.getters.elementsKeysList?.unpaginated || []),
            { key: newVal.key, isDeleted: !!newVal.deleted },
          ],
        },
      });
      this.updateLayout();
    },
  },
  async created() {
    // console.log('START created APPS-LAYOUT....')
    this.isLoadingComponents = true;

    // const force = !!this.$store.getters.currentCollective.isEvent;
    await Promise.all(
      Object.entries(this.componentsLoaders).map(
        async ([appKey, loadComponent]) => {
          const component = await loadComponent();
          this.components[appKey] = component;
        },
      ),
    );

    // NOTE: I need to use this subscription because because VueGridLayout is modifying the layout,
    // even if I don't use `.sync`.
    this.unsubscribeAction = this.$store.subscribeAction({
      before: ({ type }) => {
        if (type === ENABLED_APPS_ACTIONS.saveLayout) {
          this.updateLayout();
        }
      },
    });

    this.isLoadingComponents = false;
    // console.log('END created APPS-LAYOUT....')
  },

  beforeDestroy() {
    if (this.unsubscribeAction) this.unsubscribeAction();
  },

  methods: {
    updateLayout() {
      this.$store.commit(ENABLED_APPS_MUTATIONS.updateLayout, this.layout);
    },
    getLayoutItemContainerProps(item) {
      return (this.isGridDisabled ? item.containerProps : item.gridProps) || {};
    },

    layoutUpdatedEvent (newLayout) {
      // console.log("Updated layout: ", newLayout)
      if (newLayout.length) {
        this.$emit('reloaded', true)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes

@include media-breakpoint-up(lg) {
  .grid-layout {
    margin: -10px;
  }
}

.grid-layout {
  min-height: 1000px !important;
}

.grid-item {
  overflow: hidden;
  border-radius: 12px;
  //min-height: 200px !important; //eso estaba haciendo que se solaparan los widgets en los elements...
}
.grid-layout--landing {
  display: flex;
  flex-wrap: wrap;
  .grid-item {
    flex-basis: 100%;
    &--chat,
    &--liveStreaming {
      flex-basis: 50%;
    }
  }
}
.grid-layout--disabled {
  .grid-item {
    margin-bottom: $spacer;
    &--posts > div,
    &--communication > div {
      max-height: 100vh;
    }
  }
}

.grid-layout--editing {
  > .grid-item {
    box-shadow: 0px 0px 10px -2px $primary;
    border-radius: $card-border-radius;
    &.resizing {
      border-radius: none;
      border: 3px solid $primary;
    }
    &::v-deep > .vue-resizable-handle {
      bottom: 8px;
      right: 8px;
      background-position: center;
      background-size: 47%;
    }
  }
}
</style>
